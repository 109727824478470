let path = 'api/shop/game/'
let game = path+'lot-game/'
export const urls = {

    login: path + 'auth/login',
    get_auth_token: path + 'auth/auth-token',
    date: path + 'common/date',
    lang: path + 'common/lang',
    logout: path + 'auth/logout',
    my_auctions: path + 'my-auctions/get-list',
    accept_enter_game: game + 'accept-to-enter',
    lot_game: game + 'lot-info',
    check: game + 'check',
    lot_game_info: game + 'lot-game-info',
    get_over_time: game + 'over-time',
    confirm: game + 'confirm',
    check_gamers: game + 'check-gamers',
}
