<script setup>
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import http from "@/service/http";
import {urls} from "@/service/urls";
import cookies from "@/service/cookies";
import {onMounted} from "vue";
const props = defineProps(['mini_mobile'])
const i18n = useI18n()
const store = useStore()
const cookieLanguage = cookies.getLocale()
const locales = [
  {id: 1, label: 'O\'zbekcha', shortLabel: 'O\'Z', value: 'uz'},
  {id: 2, label: 'Ўзбекча', shortLabel: 'ЎЗ', value: 'uk'},
  {id: 3, label: 'Русский', shortLabel: 'РУ', value: 'ru'},
  {id: 4, label: 'English', shortLabel: 'EN', value: 'en'},
]

const changeLocale = (locale) => {
  http.post(urls.lang + '/' + locale.toLowerCase())
      .then(response => {
        i18n.locale.value = locale
        store.commit('set_language', locale)
        cookies.setLocale(locale)
        window.location.reload()
      }).catch(e => {
  }).finally(() => {
  })
}


</script>

<template>
  <el-dropdown class="localization" trigger="click">
    <el-button size="large" type="primary" plain>
      <i class="fa-light fa-globe"></i>
      {{ locales.filter(item => item.value === i18n.locale.value)[0].shortLabel }}
      <i class="fa-solid fa-sort-down" style="margin-bottom: 5px"></i>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <div v-for="locale in locales" :key="locale.id" @click="changeLocale(locale.value)">
          <el-dropdown-item :class="i18n.locale.value === locale.value ? 'locale-active' : ''" style="padding: 0 24px">
            {{ locale.label }}
          </el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style scoped lang="scss">

</style>
