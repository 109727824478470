<script setup>
import {onMounted, ref} from "vue";
import http from "@/service/http";
import {urls} from "@/service/urls";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {useCookies} from "vue3-cookies";

const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const cookies = useCookies().cookies
const footerText = ref()

onMounted(() => {
  http.get(urls.date)
      .then(response => {

      });
  footerText.value = i18n.t('copyright', {year: new Date().getFullYear()})

})

</script>

<template>
  <div class="footer">
    <div class="container">
      <div class="el-row el-col-gutter-x-lg">
        <div class="el-col-sm-6 el-col-xs-24 self-center footer-logo">
          <a href="/" style="line-height: 0.65" >
            <img src="https://static.e-auksion.uz/eshop/logo-white.svg" alt="logo">
          </a>
        </div>
        <div class="el-col-md-14 el-col-sm-13 el-col-xs-24 self-center">
          <div class="sh-row justify-between">
            <div class="sh-col-md-auto sh-col-sm-12 sh-col-xs-12">
              <div class="footer-office-data">
                <a href="tel:+998 71 202 21 11">+998 71 202 21 11</a>
              </div>
            </div>
            <div class="sh-col-md-auto sh-col-sm-12 sh-col-xs-12">
              <div class="footer-office-data">
                <a href="mailto:info@e-auksion.uz">info@e-auksion.uz</a>
              </div>
            </div>
            <div class="sh-col-md-auto sh-col-sm-12 sh-col-xs-12">
              <div class="footer-office-data">
                {{ $t('footer.work_time') }}
              </div>
            </div>
          </div>
        </div>
        <div class="el-col-md-4 el-col-sm-5 el-col-xs-24 self-center footer-social">
          <div class="el-row el-gutter-x-md footer-social-div">
            <div class="el-col-3">
              <a href="https://t.me/onlineauksionuz" target="_blank">
                <img src="https://static.e-auksion.uz/eshop/telegram.svg"/>
              </a>
            </div>
            <div class="el-col-3">
              <a href="https://www.instagram.com/eauksionuz/" target="_blank">
                <img src="https://static.e-auksion.uz/eshop/instagram.svg"/>
              </a>
            </div>
            <div class="el-col-3">
              <a href="https://www.facebook.com/eauksion.uz/" target="_blank">
                <img src="https://static.e-auksion.uz/eshop/facebook.svg"/>
              </a>
            </div>
            <div class="el-col-3">
              <a href="https://twitter.com/EAUKSIONUZ" target="_blank">
                <img src="https://static.e-auksion.uz/eshop/twitter.svg"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container  text-center">
        {{ footerText }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
