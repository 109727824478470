import axios from "axios";
import {constantUrls} from "@/service/constantUrls";
import cookies from "@/service/cookies";
import {ElMessage} from "element-plus";
import {i18n} from "@/locale";


if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = constantUrls.base_url_dev
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = constantUrls.base_url_prod
}

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    (response) => {
      let token = response.headers['j-auth-token']

      if (token) {
        cookies.setToken(token)
      }
      return response;//todo decode response
    },
    function (error) {
      if (!error.response) {
          ElMessage.error({
            title: i18n.global.t('error'),
            message: i18n.global.t('game_page.error_connection_time_out'),
            showClose: false,
            grouping:true
          })
        //TODO show error
        return
      }

      if (error.response.status === 401) {
        window.location.href = constantUrls.auth_url
        cookies.clearUser()
        cookies.clearToken()
      }
      return Promise.reject(error.response);
    }
);

axios.interceptors.request.use(
    (config) => {

      let token = cookies.getToken()
      if (token) {
        config.headers = Object.assign({}, {'G-Auth-Token': token}, config.headers)
      }
      return config
    },
    function (error) {
      return Promise.reject(error);
    }
);

export default {
  doLogin: (url, data) => {
    return axios({
      url: `${url}`,
      method: "post",
      data: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  get: (url, params = {}) => {
    return axios({
      url: `${url}`,
      method: "get",
      params: params,
    });
  },

  post: (url, data) => {
    return axios({
      url: `${url}`,
      method: "post",
      data: data,
    });
  },
  postParam: (url, params, data) => {
    return axios({
      url: `${url}`,
      method: "post",
      data: data,
      params: params,
    });
  },

  put: (url, data) => {
    return axios({
      url: `${url}`,
      method: "put",
      data: data,
    });
  },

  putParam: (url, params, data) => {
    return axios({
      url: `${url}`,
      method: "put",
      data: data,
      params: params,
    });
  },

  patch: (url, data) => {
    return axios({
      url: `${url}`,
      method: "patch",
      data: data,
    });
  },

  delete: (url) => {
    return axios({
      url: `${url}`,
      method: "delete",
    });
  },
};
