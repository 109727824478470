
export const routers = [
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/pages/Home.vue')
    },
    {
        path: '/my-auctions',
        name: 'GameList',
        component: () => import('@/pages/GameList.vue')
    },
    {
        path: '/game',
        name: 'GamePage',
        component: () => import('@/pages/GamePage.vue')
    },
]
