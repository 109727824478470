export const constantUrls = {

    base_url_dev: 'http://localhost:8183',
    // base_url_prod: 'https://testgame.e-auksionshop.uz',
    base_url_prod: 'https://game.e-auksionshop.uz',

    // auth_url: 'https://auth2.e-auksion.uz/login?r=my-auctions&f=http://localhost:8182',
    // auth_url: 'https://auth2.e-auksion.uz/login?r=my-auctions&f=https://testgame.e-auksionshop.uz',
    auth_url: 'https://auth.e-auksion.uz/login?r=my-auctions&f=https://game.e-auksionshop.uz',

    // eshop_front_url: 'http://localhost:8180',
    // eshop_front_url: 'https://test.e-auksionshop.uz',
    eshop_front_url: 'https://e-auksionshop.uz',

    files_url: 'https://files.e-auksion.uz/files-worker',

    auction_front_link: 'https://e-auksion.uz',
    auction_cabinet_link: 'https://cabinet.e-auksion.uz',
}
