export default {
    copyright: '© Copyright 2023-{year}. "Elektron onlayn-auksionlarni tashkil etish" AJ. Barcha huquqlar himoyalangan',
    home_page: 'Asosiy sahifa',
    error_execution: 'Amalni bajarishda xatolik yuz berdi. Iltimos, qaytadan urinib ko`ring!',
    uzs:'UZS',
    men:'MЕN',
    error:'Xatolik',
    more: 'Batafsil',
    search: 'Izlash',
    navbar: {
        login: 'Kirish',
        auth: 'Ro’yxatdan o’tish',
        placeholder: 'Lot raqami yoki mol mulk bo’yicha qidiruv',
        cash: 'Hisobingiz',
        personal_cabinet: 'Kabinetga o`tish',
    },
    menu: {
        help: 'Yordam',
        how_to_pay: 'Qanday sotib olinadi',
        about_sale: 'Muddatli to\'lov haqida',
        payment_type: 'To\'lov usullari',
        product_to_auction: 'Mahsulotni auksionga qo\'yish',
    },
    game_list: {
        title: 'Joriy auksionlar',
        inn_pinfl: 'STIR/JSHSHIR',
        phone: 'Telefon raqamingiz',
        email: 'Email',
        address: 'Manzil',
        lot_name: 'Lot nomi',
        lot_status: 'Lot holati',
        lot_number: 'Lot raqami',
        start_price: 'Boshlang’ich narx',
        start_time: 'Savdo boshlanish vaqti',
        end_time: 'Savdo tugash vaqti',
        go_auction: 'Savdoda ishtirok etish',
        error_getting_auctions: 'Joriy auksionlar ro`yxatini yuklashda xatolik yuz berdi. Iltimos, qaytadan urinib ko`ring!',
        refresh_info_1: 'Avto yangilash',
        refresh_info_2: 'soniyada bajariladi',
        not_found_auctions: 'Joriy auksionlar mavjud emas',
        continue_auction: 'Savdoni davom ettirish',
    },
    game_page:{
        meta_title: '{lot_number}-sonli lot bo`yicha auksion savdosi',
        info:'Ma`lumot',
        lot_category: 'Mol-mulk toifasi',
        lot_info: 'Lot ma`lumotlari',
        got_lot_card: 'Lot kartasiga o’tish',
        other_lots: 'Boshqa auksionlar',
        last_price_title: 'Oxirgi taklif',
        second_price_title: 'Oxirgidan avvalgi taklif',
        other_price_title: 'Ishtirokchi tasdiqlagan taklif',
        timer_title: 'Auksion tugashiga qolgan vaqt',
        buyer_number: 'Ishtirokchi raqamingiz',
        buyer_count: 'Ariza berganlar soni',
        viewer_count: 'Ishtirokchilar soni',
        success_proce_count: 'Tasdiqlangan narxlar',
        confirm_price_title: 'Tizim taklif qilgan narx',
        confirm_price: 'Narxni tasdiqlash',
        successfully_confirmed: 'Narx tasdiqlandi',
        last_confirm_price_title: 'Siz tasdiqlagan oxirgi narx',
        technical_error:'Ushbu lot bo`ychia texnik uzilish kuzatildi, batafsil ma`lumot sizga xabarnoma orqali yuboriladi!',
        you_dont_have_access_this_game:'Kechirasiz, ushbu o`yinga kirishga Sizning huquqingiz yo`q.',
        error_getting_auctions: 'Joriy auksionlar ro`yxatini yuklashda xatolik yuz berdi. Iltimos, qaytadan urinib ko`ring!',
        error_connection_time_out: 'Internet bilan bog`lanish yo`qoldi. Sahifani yangilash uchun F5 tugmasini yoki "Yangilash" tugmasini bosing.',
        auction_state: 'Auksion holati',
        auction_started: 'Auksion boshlandi',
        waiting_for_auction: 'Auksion boshlanishini kutish',
        accept_participate: 'Ishtirok etish',
        current_auctions_is_empty: 'Hozirda sizda joriy auksionlar mavjud emas',
        other_auctions_nott_exist:'Hozirda sizning boshqa joriy auksionlaringiz yo`q',
        auto_bid:'Auksion qadamiga bog`lanmagan taklif',
        start_auto_bid:'Avtomatik narx tasdiqlashni qo`shish',
        stop_auto_bid:'Avtomatik narx tasdiqlashni o`chirish',
        amount:'Miqdor',
        you_really_delete_autobid:'Siz rostdan ham auvtomatik narx tasdiqlashni o`chirmoqchimisiz',
        amount_is_empty:'Taklif narxini kiriting',
        amount_smoller_current_stavka:'Taklif narxi tizim taklif qilgan narxdan kichik',
        price_isnot_confirm: 'Hozirda lot bo`yicha narx tasdiqlanmadi',
        start_price_auction: 'Savdoda narx berish mumkin',

    },
    footer: {
        work_time: 'Ish vaqti: Du-Ju (09:00 - 17:00)',
        payment_title: 'To‘lov usullari'
    },
    countdown: {
        day: 'Kun',
        hour: 'Soat',
        minut: 'Daqiqa',
        secund: 'Soniya',
    },
}
