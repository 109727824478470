import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import {i18n} from "@/locale";


import 'element-plus/dist/index.css'
import './assets/element.scss'
import './assets/variables.css'
import './assets/main.scss'

const app = createApp(App)

app
    .use(i18n)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .mount('#app')
app.directive('regex', (el, binding) => {
    el.addEventListener('input', (e) => {
        const regex = new RegExp(binding.value);
        const value = e.target.value;
        if (regex.test(value)) {
            e.target.value = '';
        }
    })
})
