export const formatter = {
  methods: {
    numberFormat(num, dig) {
      let val = (num / 1).toFixed(dig).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    formatMoneyByAmount(amount) {
      return this.formatMoney(amount, 2, ".", " ")
    },
    doubleFormatValidate(val) {
      const innPattern = /^([0-9. ]*)$/;
      return innPattern.test(val) || this.$t('validate.invalid_text');
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    },
    innFormat(num) {
      if (num === 0 || num === null) {
        return
      }
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    getUzbPhoneString(phone_number) {
      if (phone_number == null || phone_number == "") {
        return phone_number;
      }
      phone_number=phone_number.replace(/[^0-9]/g, '');

      if (phone_number.length === 7) {
        return phone_number.substring(0, 3) + " " + phone_number.substring(3, 5) + " " + phone_number.substring(5);

      }
      if (phone_number.length < 9) {
        return phone_number;
      }
      var normalized_phone = this.getNormalizedPhoneNumber(phone_number);
      return "+998 (" + normalized_phone.substring(0, 2) + ") " + normalized_phone.substring(2, 5) + " " + normalized_phone.substring(5, 7) + " " + normalized_phone.substring(7);

    },
    getNormalizedPhoneNumber(phone) {
      if (phone == null || phone == "") {
        return phone;
      }
      if (phone.length <= 9) {
        return phone;
      }
      phone=phone.replace(/[^0-9]/g, '');
      if (phone.includes("+998")) {
        return phone.replace("+998", "");
      }
      if (phone.substring(0, 3).includes("998")) {
        phone = phone.substring(3, 12);
        return phone;
      }
      return phone;
    },
  },

}
