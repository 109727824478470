import { createRouter, createWebHistory } from 'vue-router'
import LayoutContainer from '../views/LayoutContainer.vue'
import cookies from '@/service/cookies'
import {constantUrls} from "@/service/constantUrls";
import {routers} from "@/router/routers";


let authGuard = async (to, from, next) => {
  try {
    if (typeof next !== "function") return;
    if (cookies.getUser() !== null) {
      next();
    } else {
      window.location.href = constantUrls.auth_url
    }
  } catch (e) {
  }
};


const routes = [
  {
    path: '/',
    redirect: '/my-auctions',
    name: 'LayoutContainer',
    beforeEnter: authGuard,
    component: LayoutContainer,
    children: routers
  },
  {
    path: '/redirecting',
    name: 'Redirecting',
    component: () => import('@/views/Redirecting.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
