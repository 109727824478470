import cookies from "@/service/cookies";
import {ElMessage} from "element-plus";
import {constantUrls} from "@/service/constantUrls";
import http from "@/service/http";
import {urls} from "@/service/urls";

export const CustomFunction = {
  methods: {

    showSuccessNotify(message){
      ElMessage.success({
        title: 'Info',
        message: message,
        showClose: false,
        grouping:true
      })
    },

    showErrorNotify(message) {
      ElMessage.error({
        title: this.$t('error'),
        message: message,
        showClose: false,
        grouping:true
      })
    },

    showInfoNotify(message) {
      ElMessage.info({
        title: 'Info',
        message: message,
        showClose: false,
        grouping:true
      })
    },
    reloadPage() {
      window.location.reload()
    },
    setFormRef(formRef) {
      this.formReference = formRef;
    },
    randomPassword(length) {
      var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
      var pass = "";
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      return pass;
    },

    pushAuth(){
      let url = constantUrls.auth_url+ '&l='+cookies.getLocale().toLowerCase()
      window.location = url;
    },
    resetPaginationPage() {
      this.pagination.page = 1;
    },

    pushUserDefaultPath(redirectUrl) {
      http.get(urls.get_auth_token)
          .then(response => {
            let url = redirectUrl +'/redirecting?r=messages' + '&t=' + response.data + '&l=' + cookies.getLocale()
            window.location = url;
          }).catch(e => {
      }).finally(() => {

      })
    },
    goHomePage(){
      this.redirectToPath(constantUrls.eshop_front_url+'/redirecting?r=home')
    },
    redirectToPath: (redirect_url) => {
      http.get(urls.get_auth_token)
          .then(response => {
            window.location = redirect_url + '&t=' + response.data + '&l=' + cookies.getLocale()
          }).catch(error => {
        ElMessage.error(error.data.ERROR.message)
      })
    },
    getLotImage(file_hash) {
      let servletUrl = constantUrls.files_url + '/api/v1/eshop?';
      let width = '&width=';
      let height = '&height=';
      let fileHash = '&file_hash=' + file_hash;
      let imageUrl = servletUrl + fileHash;
      return imageUrl;
    },
    routeToOtherPage(lot_id) {
      return '/game?lot=' + lot_id;
    },
    metaInfo(title, description, imageLink){
      document.title = title;

      const met_description_tag = document.querySelector('meta[name="description"]');
      if (met_description_tag) {
        met_description_tag.setAttribute('content', description);
      } else {
        const new_meta_tag = document.createElement('meta');
        new_meta_tag.name = 'description';
        new_meta_tag.content = description;
        document.head.appendChild(new_meta_tag);
      }

      if (imageLink !== null) {
        const meta_image_tag = document.querySelector('meta[name="image"]');
        if (meta_image_tag) {
          meta_image_tag.setAttribute('content', imageLink);
        } else {
          const new_meta_tag = document.createElement('meta');
          new_meta_tag.name = 'image';
          new_meta_tag.content = imageLink;
          document.head.appendChild(new_meta_tag);
        }
      }
    },
  },
}
