import {useCookies} from "vue3-cookies";

const cookies = useCookies().cookies


export default {
    getUser: () => {
        return cookies.get(`user`)
    },
    setUser: (data) => {
        cookies.set(`user`, data)
    },
    clearUser: () => {
        cookies.remove(``)
    },
    getLocale: () => {
        return cookies.get(`locale`)
    },
    setLocale: (data) => {
        cookies.set(`locale`, data)
    },
    getToken: function () {
        return cookies.get('token') !== 'null' ? cookies.get('token') : null
    },
    setToken: function (value) {
        cookies.set('token', value)
    },
    clearToken: function () {
        cookies.remove('token')
    },
}
