<template>
  <el-affix :offset="0">
    <nav class="navbar">
      <div class="navbar-header">
        <div class="container">
          <p class="time" v-html="dateTime"></p>
          <div class="conversation">
            <a href="mailto:info@shop.uz">info@shop.uz</a>
            <a href="tel:+998 (71) 202 21 11">+998 (71) 202 21 11</a>
          </div>
        </div>
      </div>

      <div class="navbar-body">
        <div class="container justify-between">
          <img v-if="mini_mobile" src="https://static.e-auksion.uz/eshop/icons/favicon-128x128.png"
               class="header-logo cursor-pointer"
               @click="goHomePage()"/>
          <img v-else src="https://static.e-auksion.uz/eshop/logo.svg" alt="logo" class="header-logo cursor-pointer"
               @click="goHomePage()">
          <div class="right-section">
            <Localization :mini_mobile="mini_mobile"/>
            <el-dropdown v-if="user !== null">
              <el-button size="large" type="primary" class="user-login" plain>
                <i class="fa-regular fa-user"></i>
                <template v-if="!mini_mobile">
                  {{ user !== null ? (user.subject_type === 1 ? user.fio : user.name) : $t('profile.guest_name') }}
                </template>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <div class="user-profile">
                    <p class="user-profile-name" v-if="user !== null">
                      {{ user !== null ? (user.subject_type === 1 ? user.fio : user.name) : $t('profile.guest_name') }}
                    </p>
                    <div class="user-wallet-money">
                      <span>{{ $t('navbar.cash') }}</span>
                      <p v-if="user !== null">{{ formatMoneyByAmount(user.balance) }} {{ $t('uzs') }}</p>
                    </div>
                    <el-divider/>
                    <el-dropdown-item command="profile"
                                      @click="redirectCabinet"
                    ><i class="fa-light fa-user"></i>{{ $t('navbar.personal_cabinet') }}
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </nav>
  </el-affix>
</template>

<script>
import {CustomFunction} from "@/service/CustomFunction";
import {constantUrls} from "@/service/constantUrls";
import {urls} from "@/service/urls";
import http from "@/service/http";
import Localization from "@/components/navbar/Localization.vue";
import SearchInput from "@/components/navbar/SearchInput.vue";
import cookies from "@/service/cookies";
import {formatter} from "@/service/formatters";

export default {
  mixins: [CustomFunction, formatter],
  components: {SearchInput, Localization},
  data() {
    return {
      serverDateStr: '--.--.-- (GMT+5)',
      serverTimeStr: '--:--:--',
      currentDate: new Date(),
      currentTimeMills: new Date().getTime(),
      serverTimeMills: new Date().getTime(),
      differenceTime: 0,
      dateTime: '',
      user: cookies.getUser(),
      mini_mobile: false,
    }
  },
  methods: {
    redirectCabinet() {
      this.redirectToPath(constantUrls.eshop_front_url + '/redirecting?r=profile')
    },
    getServerDate() {
      http.get(urls.date)
        .then(response => {

          if (response.data) {
            this.serverTimeMills = response.data;
          }

          this.differenceTime = this.currentTimeMills - this.serverTimeMills;

          if (this.differenceTime < 0) {
            this.differenceTime = this.differenceTime * (-1);
          }

          this.initCurrentDate();
          this.showTimer();
        }).catch(e => {
      }).finally(() => {
      })
    },
    initCurrentDate() {

      this.currentDate = this.differenceTime !== 0 ? new Date(new Date().getTime() - this.differenceTime) : new Date();

      this.serverDateStr = this.fillByZero(this.currentDate.getDate()) + '.' + this.fillByZero((this.currentDate.getMonth() + 1)) + '.' + this.fillByZero(this.currentDate.getFullYear());
      this.serverTimeStr = this.fillByZero(this.currentDate.getHours()) + ':' + this.fillByZero(this.currentDate.getMinutes()) + ':' + this.fillByZero(this.currentDate.getSeconds());

      this.dateTime = '<span>' + this.serverDateStr + '</span> <span>' + this.serverTimeStr + ' (GMT+5)</span>'
    },
    showTimer() {
      setInterval(() => {
        this.initCurrentDate()
      }, 1000);
    },
    fillByZero(val) {
      if (val < 10) {
        return '0' + val;
      }
      return '' + val;
    },
    handleResize() {
      if (window.innerWidth <= 600) {
        this.mini_mobile = true
      } else {
        this.mini_mobile = false
      }
    },
  },
  mounted() {
    this.initCurrentDate();
    this.getServerDate();
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}

</script>

