import {createI18n} from 'vue-i18n'
import uk from '@/locale/uk'
import uz from '@/locale/uz'
import ru from '@/locale/ru'
import en from '@/locale/en'
import cookies from "@/service/cookies";


export const i18n = createI18n({
    legacy: false,
    locale: cookies.getLocale() === null ? 'uz' : cookies.getLocale(),
    fallbackLocale: 'uk',
    messages: {
        uz,
        uk,
        ru,
        en,
    }
})
